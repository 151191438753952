
    <!-- 耕雲導師書籍及著作 -->
 <template>
    <div class="word">
        <table border="1" cellspacing="0">
            <tr v-for="(item1,index1) in length" :key="index1">
                <td class="numTitle">{{index1+1}}</td>
                <td class="contentTitle" @click="linkToDetail(list[index1])">{{list[index1].news_title}}</td>
                <td class="numTitle">
                    <div v-if="list[index1+length]">{{index1+length+1}}</div>
                </td>
                <td class="contentTitle">
                    <div v-if="list[index1+length]" @click="linkToDetail(list[index1+length])">{{list[index1+length].news_title}}</div>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import { getCateList, getBookList } from "../../api/apis";
export default {
    name: "",
    components: {},

    data() {
        return {
            list: [],
            length: 0
        };
    },

    methods: {
        //文章详情
        linkToDetail(item) {
			window.open(this.$BaseURL+'/articleDetail?name=opus&id='+item.id);
            // this.$router.push({
            //     path: "/articleDetail",
            //     query: { name: "opus", id: item.id }
            // });
        },
        // 获取子列表lists的长度,向上取整
        getLength(arr) {
            let length = arr.length;
            this.length = Math.ceil(length / 2);
            return Math.ceil(length / 2);
        }
    },
    created() {
        // this.initData(this.list);
        getCateList().then(res => {
            let id = res[1][2].id;
            getBookList(id).then(res => {
                this.list = res || [{}];
                this.length = this.getLength(res || [{}]);
            });
        });
    }
};
</script>

<style lang="stylus" scoped>
.ta-l {
    text-align: left;
}

.pl5 {
    padding-left: 5px;
}

.word {
    width: 750px;
    margin: 20px 10px;

    .title {
        font-size: 15px;
        font-weight: 600;
    }
}

table, td, tr, th {
    border: 1px solid #ACACAC;
}

table {
    width: 100%;
    font-size: 15px;

    td {
        line-height: 30px;
        cursor: pointer;
    }

    td:hover {
        color: #1482d9;
    }

    .numTitle {
        width: 60px;
    }

    .contentTitle {
        width: 378px;
        padding-left: 5px;
        text-align: left;
    }
}
</style>